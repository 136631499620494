import {useEffect, useRef, useState} from 'react';
import CountUp from 'react-countup';

export const Chart5 = () => {
    const [a,setA]=useState(12412)
    const [b,setB]=useState(14567)
    const [c,setC]=useState(13202)
    const [d,setD]=useState(15031)

    useEffect(() => {
        const interval= setInterval(()=>{
            setA(a+1)
            setB(b+1)
            setC(c+1)
            setD(d+1)

        },5000)
        return ()=>{
            clearInterval(interval)
        }
    }, [a]);
    return (
        <div className="chart5">
            <div>
                <div>婴幼儿就诊人数</div>
                <div className="count">
                    <CountUp start={10000} end={a} />
                </div>
            </div>

            <div>
                <div>青少年就诊人数</div>
                <div className="count">
                    <CountUp start={10000} end={b} />
                </div>
            </div>

            <div>
                <div>中壮年就诊人数</div>
                <div className="count">
                    <CountUp start={10000} end={a} />
                </div>
            </div>

            <div>
                <div>老年人就诊人数</div>
                <div className="count">
                    <CountUp start={10000} end={d}/>
                </div>
            </div>


        </div>
    );
};
