import px from './px';

export const baseChartOptions={

    title: {show: false},
    legend: {show: false},
    grid: {
        x: px(12),
        y: px(24),
        x2: px(20),
        y2: px(16),
        containLabel: true
    },
}
