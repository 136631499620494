import {useEffect, useRef} from 'react';
import * as echarts from 'echarts';
import {createChartsOptions} from '../shared/create-charts-options';

export const Chart3=()=>{
    const divRef=useRef(null)

    useEffect(()=>{

        // @ts-ignore
        var myChart = echarts.init(divRef.current);
        myChart.setOption( createChartsOptions(

            {

                xAxis: {
                    type: 'category',
                    data: ['8点', '9点', '10点', '11点', '12点', '13点', '14点','15点','16点','17点','18点']
                    ,axisTick:false
                },
                yAxis: {
                    type: 'value',
                },
                series: [

                    {
                        data: [152,186,123,155,132,126,156,143,186,177,160],
                        type: 'line',
                        symbol:'none'
                    },
                    {
                        data: [131,146,130,165,146,159,132,186,152,166,160],
                        type: 'line',
                        symbol:'none'
                    },
                ]
            }
        ))
        ;
    },[])
    return (

        <div className="chart-box">
            <span className="title">每日人流量分布</span>
            <div ref={divRef} className="chart3  border-line">

            </div>
        </div>
    )
}
