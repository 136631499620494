import React, {useEffect} from 'react';
import {Home} from './pages/Home';

function App() {
  useEffect(()=>{

  })
  return (
    <div className="App">
    <Home/>
    </div>
  );
}

export default App;
