import {useEffect, useRef} from 'react';
import * as echarts from 'echarts';
import {baseChartOptions} from '../shared/base-chart-options'
import px from '../shared/px';
import {createChartsOptions} from '../shared/create-charts-options';
export const Chart2=()=>{
    const divRef=useRef(null)
    useEffect(()=>{

        // @ts-ignore
        var myChart = echarts.init(divRef.current);
        myChart.setOption( createChartsOptions(

            {

                xAxis: {
                    type: 'category',
                    data: ['0-17岁', '18-30岁', '31-60岁', '60岁+'],
                    axisLine:{
                        show: false
                    },
                    axisTick:{
                        show:false
                    }
                },
                yAxis: {
                    type: 'value',
                    splitLine:{
                        show:false
                    }
                },
                series: [
                    {
                        data: [476, 654, 1080, 522],
                        type: 'bar',
                        showBackground: true,
                        backgroundStyle: {
                            color: 'rgba(180, 180, 180, 0.2)'
                        },
                        label: {
                            fontSize: 222
                        }
                    }
                ]
            }
        ))
        ;

    },[])
    return(
        <div className="chart-box">
            <span className="title">患者年龄分布情况</span>
            <div ref={divRef} className="chart2  border-line"></div>
        </div>
    )
}
