import {useEffect, useRef} from 'react';

export const Chart8=()=>{
    const divRef = useRef(null);
    let n = 0;
    useEffect(()=>{

        setInterval(()=>{
            // @ts-ignore
            const divHeight = divRef.current.offsetHeight;
            if (n < divHeight) {
                n += 10;
            } else {
                n = 0;
            }
            // @ts-ignore
            divRef.current.scrollTop=n

        },1000)

    },[])
    return(
        <div>
            <div><span className="title">公告栏目</span></div>
              <div  className="notice border-line">
                  <div ref={divRef}  className="content">
                      门诊就诊须知<br/>
                      1.请您于就诊当日提前用微信扫描下方“苹安医院信息码”完善相关信息、填写流调内容、提交保留截图、并将截图出示给预检人员，接受预检人员验码、测温及流行病学史询问。<br/>
                      2.体温≥37.3℃温度的人员请到发热门诊筛查。<br/>
                      3.来自境外及中高风险地区或有相关人员接触史的患者，请主动配合喆江省疫情防控要求实行隔离、核酸检测等防控管理，暂缓来院。您可以通过掌上医院线上诊疗。
                  </div>
              </div>
        </div>
    )
}
