import {useEffect, useRef} from 'react';
import * as echarts from 'echarts';
import {createChartsOptions} from '../shared/create-charts-options';
import px from '../shared/px';

export const Chart10=()=>{
    const divRef=useRef(null)
    useEffect(()=>{
        // @ts-ignore
        var myChart = echarts.init(divRef.current);
        myChart.setOption( createChartsOptions(
            {
                xAxis: {
                    type: 'category',
                    data: ['宋医生', '陈医生', '徐医生', '刘医生', '张医生', '胡医生', '狄医生'],
                    axisTick:false,
                axisLabel: { interval: 0, rotate: 30 }
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        data: [80, 120, 90, 140, 40, 120, 100],
                        type: 'bar',
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: '#83bff6' },
                                { offset: 0.5, color: 'rgba(35, 120, 247,0.3) ' },
                                { offset: 1, color: 'rgba(35, 120, 247,0) '}
                            ])
                        },
                    }, {
                        data: [90,70, 110, 75, 80, 90, 120],
                        type: 'bar',
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: 'rgb(128, 255, 165)' },
                                { offset: 0.5, color: 'rgb(128, 255, 165,0.3)' },
                                { offset: 1, color: 'rgb(128, 255, 165,0)'}
                            ])
                        },
                    }
                ]
            }
        ))
        ;
    })
    return (
        <div>
            <div><span className="title">医生接诊情况分析</span></div>

            <div  ref={divRef} className="chart10 border-line"></div>
        </div>
    )
}
