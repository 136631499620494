import {baseChartOptions} from './base-chart-options';
import px from '../shared/px'
export const createChartsOptions=(options: any )=>{
    const result = {
        ...baseChartOptions,
        ...options,
    };
    if (!(options?.xAxis?.axisLabel?.fontSize)) {
        result.xAxis = result.xAxis || {};
        result.xAxis.axisLabel = result.xAxis.axisLabel || {};
        result.xAxis.axisLabel.fontSize = px(16);
    }
    if (!(options?.yAxis?.axisLabel?.fontSize)) {
        result.yAxis = result.yAxis || {};
        result.yAxis.axisLabel = result.yAxis.axisLabel || {};
        result.yAxis.axisLabel.fontSize = px(16);
        result.yAxis.splitLine={
            show:false
        }

    }
    return result
}
