import {useEffect, useRef} from 'react';
import * as echarts from 'echarts';
import {createChartsOptions} from '../shared/create-charts-options';
import px from '../shared/px';

export const Chart9=()=>{
    const divRef=useRef(null)

    useEffect(()=>{
        // @ts-ignore
        var myChart = echarts.init(divRef.current);
        myChart.setOption( createChartsOptions(
            {
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    axisTick:false,
                    data: ['科室1', '科室2', '科室3', '科室4', '科室5', '科室6', '科室7']
                },
                yAxis: {
                    type: 'value',
                    show:false
                },
                grid: {
                    x: px(-30),
                    y: px(20),
                    x2: px(25),
                    y2: px(10),
                    containLabel: true
                },
                series: [
                    {
                        data: [1020, 232, 801, 834, 690, 1230, 1020],
                        type: 'line',
                        smooth: true,
                        lineStyle: {
                            width: 0
                        },
                        showSymbol: false,
                        areaStyle: {
                            opacity: 0.5,
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: 'rgb(128, 255, 165)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgb(1, 191, 236)'
                                }
                            ])
                        },
                    },
                    {
                        data: [520, 812, 601, 1134, 390, 1130, 820],
                        type: 'line',
                        smooth: true,
                        lineStyle: {
                            width: 0
                        },      showSymbol: false,

                        areaStyle: {
                            opacity: 0.5,

                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: 'rgb(55, 162, 255)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgb(116, 21, 219)'
                                }
                            ])
                        }
                    }
                ]
            }

        ))
        ;
    })
    return (

        <div>
            <div><span className="title">各科室床位使用情况</span></div>

            <div className="chart9  ">
                    <div  ref={divRef} className="chart-line border-line">
                    </div>

            </div>

        </div>
    )
}
