import './home.scss'
import headerBackPic from '../images/header.png'

import {Chart1} from '../components/chart-1';
import {Chart2} from '../components/chart-2';
import {Chart3} from '../components/chart-3';
import {Chart4} from '../components/chart-4';
import {Chart5} from '../components/chart-5';

import {Chart6} from '../components/chart-6';
import {Chart7} from '../components/chart-7';
import {Chart8} from '../components/chart-8';
import {Chart9} from '../components/chart-9';
import {Chart10} from '../components/chart-10';
import {Map} from '../components/map';
export const Home=()=>{

    return(
        <div className="home" >
            <header style={{backgroundImage:`url(${headerBackPic})`}}><span>苹安医院数据大屏</span></header>
            <div className="main">
                <section className="section-1">
                    <Chart1></Chart1>
                    <Chart2></Chart2>
                    <Chart3></Chart3>
                    <Chart4></Chart4>
                </section>
                <section className="section-2 border-line">
                    <Chart5></Chart5>
                </section>
                <section className="section-3">
                    <Map></Map>

                </section>
                <section className="section-4 ">
                    <Chart6></Chart6>
                </section>
                <section className="section-5">
                    <Chart7></Chart7>
                    <Chart8></Chart8>
                    <Chart9/>
                    <Chart10></Chart10>
                </section>
            </div>
        </div>

    )
}
