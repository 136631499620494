import * as echarts from 'echarts';
import {useEffect, useRef} from 'react';
import px from '../shared/px'
export const Chart1 = () => {
    const divRef = useRef(null);

    useEffect(() => {
        // @ts-ignore
        var myChart = echarts.init(divRef.current);
        myChart.setOption({

            visualMap: {
                show: false,
                inRange: {
                    colorAlpha: 0.8,
                    color: ['#363940', '#1751B2', '#013998', '#123293']
                }
            },
            series: [
                {
                    type: 'treemap',
                    data: [
                        {
                            name: '关节炎',
                            value: 10
                        },
                        {
                            name: '腰间盘突出',
                            value: 30
                        },
                        {
                            name: '新冠病毒',
                            value: 60
                        },
                        {
                            name: '猴痘',
                            value: 10
                        },
                        {
                            name: '流行性感冒',
                            value: 20
                        },
                        {
                            name: '呼吸道感染',
                            value: 40
                        }
                    ],
                    breadcrumb: {show: false},
                    width:"95%",
                    height:"95%",
                    label:{
                        overflow:'break'
                    }
    },
    ]

    })
        ;
    }, []);

    return (
        <div className="chart-box">
            <span className="title">疾病关键词搜索</span>
            <div ref={divRef} className="chart1  border-line"></div>
        </div>
    );
};
