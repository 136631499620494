export const Chart6 = () => {
    return (
        <div>
            <div><span className="title">设备使用情况统计</span></div>
            <div className="border-line back-blue">
                <div className="remarks">有创呼吸机考虑进货</div>
                <div className="equipment">
                    <div className="item">
                        <div>心电图机</div>
                        <div className="shangsheng">
                            <svg className="icon" aria-hidden="true">
                                <use xlinkHref="#icon-shangsheng"></use>
                            </svg>
                            <div>4%</div>
                        </div>
                        <div>110台</div>

                    </div>
                    <div className="item">
                        <div>心脏除颤仪</div>
                        <div className="xiajiang">
                            <svg className="icon" aria-hidden="true">
                                <use xlinkHref="#icon-xiajiang"></use>
                            </svg>
                            <div>9%</div>
                        </div>
                        <div>120台</div>

                    </div>
                    <div className="item">
                        <div>有创呼吸机</div>
                        <div className="xiajiang">
                            <svg className="icon" aria-hidden="true">
                                <use xlinkHref="#icon-xiajiang"></use>
                            </svg>
                            <div>15%</div>
                        </div>
                        <div>143台</div>

                    </div>
                    <div className="item">
                        <div>双筒显微镜</div>
                        <div className="shangsheng">
                            <svg className="icon" aria-hidden="true">
                                <use xlinkHref="#icon-shangsheng"></use>
                            </svg>
                            <div>5%</div>
                        </div>
                        <div>90台</div>

                    </div>
                    <div className="item">
                        <div>骨科牵引床</div>
                        <div className="shangsheng">
                            <svg className="icon" aria-hidden="true">
                                <use xlinkHref="#icon-shangsheng"></use>
                            </svg>
                            <div>12%</div>
                        </div>
                        <div>72台</div>

                    </div>
                    <div className="item">
                        <div>普通X光机</div>
                        <div className="shangsheng">
                            <svg className="icon" aria-hidden="true">
                                <use xlinkHref="#icon-shangsheng"></use>
                            </svg>
                            <div>9%</div>
                        </div>
                        <div>151台</div>

                    </div>

                </div>
            </div>

        </div>
    );
};

