import * as echarts from 'echarts';
import {useEffect, useLayoutEffect, useRef, useState} from 'react';

const patientsData = [
    {id: '1', date: '20220810', name: '沈*', type: '呼吸内科', status: '治疗中'},
    {id: '2', date: '20220810', name: '陈*明', type: '消化科', status: '已治愈'},
    {id: '3', date: '20220810', name: '蒋*丽', type: '五官科', status: '治疗中'},
    {id: '4', date: '20220810', name: '贾*平', type: '皮肤科', status: '已治愈'},
    {id: '5', date: '20220810', name: '石*坚', type: '呼吸内科', status: '已治愈'},
    {id: '6', date: '20220810', name: '魏*', type: '呼吸内科', status: '治疗中'},
    {id: '7', date: '20220810', name: '陈*池', type: '消化科', status: '已治愈'},
    {id: '8', date: '20220810', name: '黄*桐', type: '五官科', status: '治疗中'}
];

export const Chart4 = () => {
    const divRef = useRef(null);
    let n = 0;
    useEffect(() => {
        setInterval(() => {
            // @ts-ignore
            const divHeight = divRef.current.offsetHeight;
            if (n < divHeight) {
                n += 10;
            } else {
                n = 0;
            }
            // @ts-ignore
            divRef.current.scrollTop=n
        }, 1000);
    }, []);
    return (
        <div className="chart-box">
            <span className="title">患者就诊情况等级</span>
            <div className="chart4 border-line">
                <div className="table-head">
                    <div className="date">日期</div>
                    <div>患者姓名</div>
                    <div>疾病名称</div>
                    <div>治愈情况</div>
                </div>
                <div className="table-box" ref={divRef} >
                        {patientsData.map(patient =>
                            <div className="itemBox" key={patient.id}>
                                <div className="date">{patient.date}</div>
                                <div>{patient.name}</div>
                                <div>{patient.type}</div>
                                <div className={patient.status==='已治愈'?'green':''}>{patient.status}</div>
                            </div>
                        )}

                </div>
            </div>
        </div>

    );
};
