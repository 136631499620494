import CountUp from 'react-countup';
import {useEffect, useState} from 'react';


export const Chart7 = () => {
    const [n,setN]=useState(171236)

    useEffect(()=>{

       const interval= setInterval(()=>{
            setN(n+1)

        },5000)

        return ()=>{
           clearInterval(interval)
        }
    },[n])
    return (
        <div>
            <div><span className="title">预约人数统计</span></div>
            <div className="content-box border-line">
                <div className="box1">预约总人数</div>
                <div className="box2">
                    <CountUp start={10000} end={n} duration={1} />

                </div>
                <div className="container">
                    <div className="count-box">
                        <div className="icon-box">
                            <svg className="icon" aria-hidden="true">
                                <use xlinkHref="#icon-a-3jizhenke"></use>
                            </svg>
                        </div>
                        <div className="content">
                            <div>急诊人数</div>
                            <div>11239</div>
                        </div>
                    </div>
                    <div className="count-box">
                        <div className="icon-box">
                            <svg className="icon" aria-hidden="true">
                                <use xlinkHref="#icon-a-1neike"></use>
                            </svg>
                        </div>
                        <div className="content">
                            <div>门诊人数</div>
                            <div>15673</div>
                        </div>
                    </div>

                    <div className="count-box">
                        <div className="icon-box">
                            <svg className="icon" aria-hidden="true">
                                <use xlinkHref="#icon-ico_yiyuanguanjia_banlichuyuan"></use>
                            </svg>
                        </div>
                        <div className="content">
                            <div>住院人数</div>
                            <div>3287</div>
                        </div>
                    </div>

                    <div className="count-box">
                        <div className="icon-box">
                            <svg className="icon" aria-hidden="true">
                                <use xlinkHref="#icon-ico_yiyuanguanjia_banliruyuan"></use>
                            </svg>
                        </div>
                        <div className="content">
                            <div>出院人数</div>
                            <div>25672</div>
                        </div>
                    </div>




                </div>
            </div>
        </div>
    );
};
